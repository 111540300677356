//font size
$px64: 4rem;
$px62: 3.875rem;
$px60: 3.75rem;
$px58: 3.625rem;
$px56: 3.5rem;
$px55: 3.438rem;
$px54: 3.375rem;
$px52: 3.25rem;
$px50: 3.125rem;
$px48: 3rem;
$px46: 2.875rem;
$px45: 2.8125rem;
$px44: 2.75rem;
$px42: 2.625rem;
$px40: 2.5rem;
$px38: 2.375rem;
$px36: 2.25rem;
$px35: 2.1875rem;
$px34: 2.125rem;
$px32: 2rem;
$px30: 1.875rem;
$px28: 1.75rem;
$px26: 1.625rem;
$px25: 1.563rem;
$px24: 1.5rem;
$px22: 1.375rem;
$px21: 1.313rem;
$px20: 1.25rem;
$px19: 1.1875rem;
$px18: 1.125rem;
$px17: 1.0625rem;
$px16: 1rem;
$px15: 0.9375rem;
$px14: 0.875rem;
$px13: 0.8125rem;
$px12: 0.75rem;

$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 95%,
  xl: 1140px,
  xxl: 1255px
) !default;


$primary:       #045100;
$secondary:     #067200;
$info:          #0B192D;
$black : #000;
$yellow:#F2CF48;
$optional-color: #2B2B2B;
$optional-color2: #ff8a00;
$whitecolor: #fff;





$text-primary: #141414;
$secondary-font :"Montserrat", serif;
$plus-font :"Plus Jakarta Sans", serif;

$body-bg:                   #fff;
$body-color:                $text-primary;
$color-light:               #E4EEFA;


$grid-columns:                12 !default;
$grid-gutter-width:           30px;
$grid-row-columns:            6 !default;
$font-family-sans-serif:      "Open Sans", serif;

$font-family-base:            $font-family-sans-serif;
$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$link-decoration:             none !default;
$link-color:                  $primary !default;

$headings-font-family:       $secondary-font;
$headings-font-weight:        700;
$link-hover-decoration:       none;
$input-btn-padding-y:         10px;
$input-btn-padding-x:         30px;

$btn-font-family:             "Montserrat", serif;
$btn-font-weight:             700;
$btn-font-size:               $px14;
$btn-border-radius:           0px;


$accordion-padding-y:                     1rem;
$accordion-padding-x:                     1.25rem;
$accordion-color:                         #fff; // Sass variable because of $accordion-button-icon
$accordion-bg:                            #fff;

$accordion-border-width:                  0px;
$accordion-border-radius:                 0px;
$accordion-inner-border-radius:           0px;

$accordion-body-padding-y:                5px;
$accordion-body-padding-x:                20px;

$accordion-button-padding-y:              15px;
$accordion-button-padding-x:              20px;

$accordion-button-color:                  #1D1711;
$accordion-button-bg:                     #fff;

$accordion-button-active-bg:             $primary;
$accordion-button-active-color:           #fff;

$accordion-icon-width:                    1.65rem;
$accordion-icon-color:                    $primary;
$accordion-icon-active-color:             #fff;

$accordion-icon-transform:                rotate(0deg);

$accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' fill='#{$accordion-icon-color}'><path d='M241 337c-9.4 9.4-24.6 9.4-33.9 0L47 177c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l143 143L367 143c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9L241 337z'/></svg>");
$accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' fill='#{$accordion-icon-active-color}'><path d='M207 143c9.4-9.4 24.6-9.4 33.9 0L401 303c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-143-143L81 337c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L207 143z'/></svg>");


html {
  scroll-behavior: auto;
}

     


