.footer {
    background-color: $whitecolor;
    padding: 73px 0 14px 0;
    .container {
        max-width: 1400px;
    }
    .inside_footer {
        display: flex;
        gap: 162px;

        @media screen and (max-width:991px) {
            gap: 20px;
        }

        @media screen and (max-width:991px) {
            gap: 50px;
            flex-flow: column;
        }
        .foologo{
            @media screen and (min-width:991px) {
                width: 40%;
            }
           
        }

        .foo_content {
            display: flex;
            justify-content: space-between;
            width: 100%;
            gap: 60px;
            @media screen and (min-width:991px) {
                width: 60%;
            }

            @media screen and (max-width:400px) {
                flex-flow: column;
                gap: 20px;
            }

            .foo_box {
                @media screen and (max-width:767px) {
                    width: 50%;
                }
                @media screen and (max-width:400px) {
                    width: 100%;
                }
                h4{
                    font-size: 15px;
                    letter-spacing: -0.5px;
                }
                ul {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 10px;
                    column-gap: 20px;

                    li {
                        width: calc(50% - 10px);

                        @media screen and (max-width:991px) {
                            width: 100%;
                        }

                        a {
                            color: $black;
                            font-size: 15px;
                            font-weight: 400;
                            transition: all 0.3s;
                            letter-spacing: -0.5px;
                            position: relative;
                            &::after{
                                position: absolute;
                                left: 0;
                                bottom: -2px;
                                height: 1px;
                                width: 0;
                                content: "";
                                background-color: $primary;
                                transition: all 0.3s;
                            }

                            &:hover {
                                color: $primary;
                                transition: all 0.3s;
                                &::after{
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.websiteby {
    display: inline-block;
    text-align: right;
    width: 100%;
    font-size: 12px;
    font-style: italic;
    color: $black;
    margin-top: 24px;
}