main {
    position: relative;
}

.visisble main {
    margin-top: 154px;
}

.hero_sec {
    position: relative;
    height: calc(80vh - 154px);

    @media screen and (max-width:1199px) {
        height: 100%;
        // margin-bottom: 30px;
    }

    .banner {
        height: 100%;
        background-repeat: no-repeat;
        width: 100%;
        background-size: cover;

        @media screen and (min-width:768px) and (max-width:1199px) {
            height: 500px;
            background-position: center;
        }

        @media screen and (max-width:767px) {
            height: 300px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .banner_content {
        position: absolute;
        bottom: 13px;
        left: 0;
        right: 0;
        z-index: 2;
        text-align: center;

        @media screen and (max-width:767px) {
            position: relative;
            margin-top: -68px;
        }

        .heading_text {
            display: inline-block;
            max-width: 552px;
            width: 100%;
            padding: 13px 20px;
            background: rgb(243, 214, 74);
            background: radial-gradient(circle, rgba(243, 214, 74, 0.6279761904761905) 0%, rgba(210, 192, 103, 0.0845588235294118) 100%);

            h1 {
                color: $text-primary;
                font-size: calc(26px + (32 - 26) * ((100vw - 320px) / (1440 - 320)));
                font-weight: 500;

                @media screen and (min-width:1441px) {
                    font-size: 32px;
                }
            }

            p {
                font-size: 16px;
                font-weight: 400;
            }
        }

        .address_box {
            margin-top: 10px;

            ul {
                display: flex;
                justify-content: space-between;
                gap: 25px;
                flex-wrap: wrap;

                @media screen and (max-width:1199px) {
                    gap: 10px;
                }


                li {
                    width: calc(20% - 20px);

                    @media screen and (min-width:992px) and (max-width:1199px) {
                        width: calc(20% - 10px);
                    }

                    @media screen and (max-width:991px) {
                        width: calc(50% - 5px);
                    }

                    @media screen and (max-width:480px) {
                        width: calc(100% - 5px);
                    }

                    a {
                        @include gredient;
                        padding: 10px 20px;
                        display: flex;
                        justify-content: center;
                        flex-flow: column;
                        font-size: $px12;
                        text-transform: uppercase;
                        font-family: $secondary-font;
                        font-weight: 700;
                        // word-break: break-all;
                        height: 100%;
                        gap: 2px;

                        @media screen and (min-width:768px) and (max-width:1400px) {
                            padding: 10px;
                        }

                        @media screen and (min-width:992px) and (max-width:1100px) {
                            font-size: 10px;
                        }

                        &:hover {
                            @include hover;
                        }

                        span {
                            display: block;

                            i {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}

.welcome_point {
    background-color: $primary;
    padding: 55px 0;
    border-bottom: solid 8px $yellow;
    box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.2);
    position: relative;

    &::before {
        background: url(../images/vector1.png) no-repeat;
        position: absolute;
        left: 0;
        top: -168px;
        content: "";
        width: 100%;
        height: 100%;
    }

    &::after {
        background: url(../images/vector2.png) no-repeat bottom right;
        position: absolute;
        right: 0;
        bottom: 0;
        content: "";
        width: 100%;
        height: 100%;
    }

    ul {
        display: flex;
        justify-content: space-between;
        position: relative;
        z-index: 222;
        gap: 20px;
        flex-wrap: wrap;

        @media screen and (max-width:991px) {
            justify-content: center;
            flex-wrap: wrap;
        }

        li {
            a {
                @include gredient2;
                @include shadow;
                display: flex;
                flex-flow: column;
                align-items: center;
                justify-content: center;
                gap: 23px;
                border-radius: 23px;
                width: 185px;
                height: 177px;
                text-align: center;
                font-family: $secondary-font;
                font-weight: 700;
                color: $optional-color;
                padding: 24px 10px;

                @media screen and (min-width:992px) and (max-width:1199px) {
                    width: 165px;
                }

                p {
                    font-size: 14px;
                }
            }
        }
    }
}

.customer_review {
    padding: 83px 0 34px 0;

    @media screen and (max-width:767px) {
        padding: 40px 0;
    }

    .review_heading {
        h2 {
            font-size: calc(20px + (48 - 20) * ((100vw - 320px) / (1440 - 320)));
            font-weight: 600;
            color: $primary;
            line-height: 1.3;

            @media screen and (min-width:1441px) {
                font-size: 48px;
            }
        }

        p {
            font-family: $plus-font;
            font-weight: 400;
            line-height: 20px;
            font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1440 - 320)));

            @media screen and (min-width:1441px) {
                font-size: 20px;
            }

            @media screen and (max-width:767px) {
                margin-top: 0;
            }
        }
    }

    .testimonialSlider {
        overflow: hidden;
        width: 100%;
        position: relative;
        margin-top: 24px;
        z-index: 222;

        @media screen and (min-width:768px) {
            &::before {
                position: absolute;
                left: 0;
                top: 0;
                background: url(../images/bg.png) no-repeat;
                content: "";
                width: 215px;
                height: 100%;
                z-index: 22;
            }

            &::after {
                position: absolute;
                right: 0;
                top: 0;
                background: rgb(255, 255, 255);
                background: linear-gradient(80deg, rgba(255, 255, 255, 0.2358193277310925) 0%, rgba(255, 255, 255, 0.9304971988795518) 100%);
                content: "";
                width: 215px;
                height: 100%;
                z-index: 22;
            }
        }

        .review_carousel {
            li {}
        }

        .slick-list {
            padding: 0 180px;

            @media screen and (max-width:1100px) {
                padding: 0 60px;
            }

            @media screen and (max-width:767px) {
                padding: 0 30px;
            }
        }

        .slider-wrapper {
            display: flex;
            animation: scroll 40s linear infinite;

            .slideCard {
                box-shadow: 0 0 0 0.6px #CCCFD4;
                border-radius: 16px;
                padding: 24px 28px;
                background: #F9FAFC;
                flex: 0 0 480px;
                margin: 10px;

                .user {
                    width: 44px;
                    height: 44px;
                    margin-right: 16px;
                }

                .info {
                    font-family: $plus-font;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 1.43;
                    text-align: left;
                    color: $info;
                }

                .rating {
                    margin: 10px 0;
                }

                .discription {
                    font-family: $font-family-sans-serif;
                    font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1440 - 300)));
                    font-weight: 500;
                    line-height: 1.5;
                    text-align: left;
                    color: $info;

                    @media screen and (min-width:1441px) {
                        font-size: 16px;
                    }
                }
            }
        }

        @keyframes scroll {
            0% {
                transform: translateX(0);
            }

            100% {
                transform: translateX(-50%);
            }
        }
    }

    &.subpagereview {
        @media screen and (min-width:991px) {
            padding-bottom: 100px;
        }

    }

    &.storage {
        .testimonialSlider:after {
            width: 70px;
            height: 195px;
            top: 10px;
        }

        .testimonialSlider::before {
            width: 70px;
            height: 195px;
            top: 10px;
        }
    }
}

.slideCard {
    box-shadow: 0 0 0 0.6px #CCCFD4;
    border-radius: 16px;
    padding: 24px 28px;
    background: #F9FAFC;
    flex: 0 0 480px;
    margin: 10px;

    .user {
        width: 44px;
        height: 44px;
        margin-right: 16px;
    }

    .info {
        font-family: $plus-font;
        font-size: 14px;
        font-weight: 700;
        line-height: 1.43;
        text-align: left;
        color: $info;
    }

    .rating {
        margin: 10px 0;
    }

    .discription {
        font-family: $font-family-sans-serif;
        font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1440 - 300)));
        font-weight: 500;
        line-height: 1.5;
        text-align: left;
        color: $info;

        @media screen and (min-width:1441px) {
            font-size: 16px;
        }
    }
}


.about_sls {
    padding: 119px 0 67px 0;

    @media screen and (max-width:991px) {
        padding: 30px 0px;
    }

    h2 {
        font-size: calc(20px + (48 - 20) * ((100vw - 320px) / (1440 - 320)));
        font-weight: 600;
        color: $primary;
        letter-spacing: -1px;
        margin-bottom: 15px;

        @media screen and (min-width:1441px) {
            font-size: 48px;
        }

        span {
            font-weight: 700;
        }
    }

    p {
        color: $black;
        font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1440 - 320)));
        line-height: 1.6;
        margin-bottom: 20px;
        padding-right: 20px;

        @media screen and (min-width:1441px) {
            font-size: 18px;
        }
    }

    img {
        @media screen and (max-width:767px) {
            width: 100%;
        }
    }

    .sls_info {
        display: flex;
        row-gap: 40px;
        margin-top: 60px;

        @media screen and (max-width:991px) {
            row-gap: 0px;
            margin-top: 30px;
        }

        h3 {
            font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1440 - 320)));
            letter-spacing: -0.5px;

            @media screen and (min-width:1441px) {
                font-size: 24px;
            }
        }

        p {
            font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1440 - 320)));
            color: rgba(0, 0, 0, 0.8);
            padding-right: 0;
            margin-top: 10px;
            letter-spacing: -0.5px;
            padding-right: 30px;

            @media screen and (min-width:1441px) {
                font-size: 18px;
            }

            @media screen and (max-width:767px) {
                padding-right: 0;
            }
        }
    }
}

.map_sec {
    position: relative;

    &::before {
        position: absolute;
        top: 0;
        width: 100%;
        height: 25px;
        background: rgba(4, 79, 0, 0.29);
        content: "";
        z-index: 2;
    }

    #map_wrapper2 {
        height: 615px;

        @media screen and (max-width:401px) {
            height: 800px;
        }
    }

    #map_canvas2 {
        width: 100%;
        height: 100%;

        h3 {
            font-size: 12px;
        }
    }

    .map_address_box {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5%;

        @media screen and (max-width:767px) {
            left: 0;
            right: 0;
        }
    }

    .address_box {
        background-color: $secondary;
        margin-left: auto;
        width: 514px;
        border-radius: 23px;
        padding: 27px 16px 41px 22px;
        @include shadow;

        @media screen and (max-width:767px) {
            margin: 0 auto;
            max-width: 514px;
            width: 90%;
        }

        h4 {
            color: $whitecolor;
            font-family: $secondary-font;
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 21px;

            @media screen and (max-width:767px) {
                font-size: $px20;
            }
        }

        ul {
            li {
                background-color: #A3DBA0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 7px 15px;
                border-radius: 5px;
                margin-bottom: 13px;

                &:last-child {
                    margin-bottom: 0;
                }

                @media screen and (max-width:400px) {
                    flex-flow: column;
                }

                .box_inside {
                    display: flex;
                    align-items: center;
                    gap: 11px;
                    width: 65%;

                    @media screen and (max-width:400px) {
                        width: 100%;
                    }

                    img {
                        width: 16px;
                    }

                    h5 {
                        color: #2B2B2B;
                        font-size: 14px;
                        font-family: $secondary-font;
                        font-weight: 700;
                        margin: 0;
                        padding: 0;
                    }

                    p {
                        color: #2B2B2B;
                        font-size: 14px;
                        font-family: $secondary-font;
                        font-weight: 400;
                    }
                }

                .btn-primary {
                    width: 35%;

                    @media screen and (max-width:400px) {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.select_loc_bar {
    background-color: $primary;
    padding: 49px 0;
    border-bottom: solid 4px #f2cf48;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .2);

    .bar_content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width:599px) {
            flex-flow: column;
            text-align: center;
            gap: 20px;
        }

        h3 {
            color: $whitecolor;
            font-size: calc(21px + (32 - 21) * ((100vw - 320px) / (1440 - 320)));

            @media screen and (min-width:1441px) {
                font-size: 32px;
            }
        }

        p {
            color: #C9C9C9;
            font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1440 - 320)));
            font-weight: 400;

            @media screen and (min-width:1441px) {
                font-size: 24px;
            }
        }
    }
}

.devider {
    img {
        width: 100%;
        height: 1px;
    }

    @media screen and (min-width:768px) {
        display: block;
        text-align: center;
    }
}


.gallery_section_wrap {
    margin-top: 48px;

    @media screen and (max-width:767px) {
        margin-top: 20px;
    }

    @media(max-width: 991px) {
        // max-width: 700px;
        max-width: 100%;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 16px;

        @media(max-width: 767px) {
            border-radius: 10px;
        }
    }

    .slick-slide {
        font-size: 0;
    }

    .main-slider {
        height: calc(100% - 108px);
        height: 335px;

        @media(max-width: 767px) {
            height: 320px;
        }

        @media(max-width: 575px) {
            height: 260px;
        }

        .slick-track {
            display: flex !important;
            height: 100%;
        }

        .slick-list {
            height: 100%;
        }

        .slick-slide {
            height: inherit !important;
        }
    }

    .slider-nav {
        padding-top: 10px;
        margin: 0 -5px;

        @media(max-width: 767px) {
            margin: 0 -2px;
        }

        .slick-list {}

        .slick-track {
            transform: none !important;
        }

        .slick-slide {
            font-size: 0;
            padding: 0 5px;
            overflow: hidden;

            @media(max-width: 767px) {
                padding: 0 2px;
            }

            a {
                display: none;
                position: relative;
                overflow: hidden;
                border-radius: 8px;

                &.up-img {
                    display: block;
                }

                .more {
                    position: absolute;
                    left: 0;
                    top: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 20px;
                    font-weight: bold;
                    color: #fff;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(11, 25, 45, .3);
                }
            }
        }

        img {
            aspect-ratio: 1;
            border-radius: 8px;

            @media(max-width: 767px) {
                border-radius: 4px;
            }
        }
    }

    .map_sec {
        @media screen and (max-width:991px) {
            margin-top: 30px;
        }

        #map_wrapper {
            height: 335px;
            border-radius: 16px;
            overflow: hidden;
            border: solid 1px #D2D2D2;
        }

        img {
            object-fit: contain;
        }
    }

    #map_canvas {
        overflow: visible !important;
        width: 100% !important;
        height: 100% !important;

        h3 {
            font-size: 12px;
        }
    }

    .gallery_inner {
        max-width: 1400px;
        width: 95%;
        margin: 0 auto;
    }

    .address_point {
        margin-top: 13px;

        .address {
            display: flex;
            justify-content: space-between;

            .add_info {
                width: 70%;

                h3 {
                    font-size: $px16;
                    font-weight: 700;
                    color: $black;

                    @media screen and (max-width:767px) {
                        font-size: 14px;
                    }
                }

                p {
                    font-size: $px16;
                    font-weight: 400;
                    color: $black;
                    margin-top: 6px;

                    @media screen and (max-width:767px) {
                        font-size: 14px;
                    }
                }
            }

            .email_info {
                text-align: right;

                .call {
                    font-size: $px16;
                    font-weight: 700;
                    color: $black;
                    text-decoration: underline;

                    @media screen and (max-width:767px) {
                        font-size: 14px;
                    }
                }

                .email {
                    font-size: $px16;
                    font-weight: 400;
                    color: $black;
                    margin-top: 6px;
                    display: block;

                    @media screen and (max-width:767px) {
                        font-size: 14px;
                    }
                }
            }
        }

        .billing {
            font-weight: 700;
            color: $whitecolor;
            background-color: $secondary;
            font-size: $px16;
            text-align: center;
            padding: 4px;
            margin-top: 10px;

            @media screen and (max-width:767px) {
                font-size: 14px;
            }
        }
    }

}

.unit_wrapper_sec {
    margin-top: 78px;
    margin-bottom: 32px;

    @media screen and (max-width:767px) {
        margin-top: 40px;
    }

    .unitname_row {
        display: inline-block;
        width: 100%;
        margin-bottom: 10px;

        @media screen and (max-width:767px) {
            display: none;
        }

        ul {
            display: flex;
            justify-content: space-around;

            li {
                color: #606060;
                font-size: $px20;
                font-weight: 700;
                font-family: $secondary-font;
                width: 25%;
                text-align: center;

                @media screen and (max-width:991px) {
                    font-size: 16px;
                }

                &:nth-child(1) {
                    text-align: left;
                    padding-left: 49px;
                    width: 20%;
                }
                &:nth-child(2) {
                    width: 30%;
                }
            }
        }
    }

    .unit_row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: solid 2px $secondary;
        background-color: #F2F2F2;
        border-radius: 8px;
        min-height: 144px;
        padding: 10px 52px 10px 49px;
        margin-bottom: 25px;

        @media screen and (min-width:769px) and (max-width:1075px) {
            padding: 10px 15px;
        }
        @media screen and (max-width:768px) {
            flex-flow: column;
            height: 100%;
            text-align: center;
            padding: 10px 15px;
            gap: 10px;
        }

        .sizes {
            font-size: $px35;
            color: #0B192D;
            font-family: $secondary-font;
            font-weight: 700;
            width: 20%;

            @media screen and (min-width:769px) and (max-width:1075px) {
                font-size: $px26;
            }

            @media screen and (max-width:768px) {
                width: 100%;
                text-align: right;
            }

            span {
                display: block;
                width: 100%;
                color: #0B192D;
                text-transform: uppercase;
                font-size: $px12;
            }

            @media screen and (max-width:768px) {
                &:before {
                    content: attr(data-label);
                    float: left;
                    color: #606060;
                    font-size: 16px;
                    font-weight: bold;
                }
            }
        }

        .spcl {
            width: 30%;

            @media screen and (max-width:768px) {
                width: 100%;
                text-align: right;
            }

            @media screen and (max-width:768px) {
                &:before {
                    content: attr(data-label);
                    float: left;
                    color: #606060;
                    font-size: 16px;
                    font-weight: bold;
                }
            }

            ul {
                li {
                    color: #E70004;
                    font-size: $px16;
                    font-family: $secondary-font;
                    font-weight: 400;
                    position: relative;
                    padding-left: 12px;
                    @media screen and (min-width:769px) and (max-width:1075px) {
                        font-size: $px14;
                    }

                    &::before {
                        position: absolute;
                        left: 0;
                        top: 10px;
                        margin-right: 5px;
                        width: 4px;
                        height: 4px;
                        background-color: #E70004;
                        border-radius: 100%;
                        content: "";

                        @media screen and (max-width:768px) {
                            display: inline-block;
                            position: relative;
                            vertical-align: middle;
                            top: -2px;
                        }
                    }

                    &.offertxt {
                        font-weight: 700;
                        font-size: $px19;
                        @media screen and (min-width:769px) and (max-width:1075px) {
                            font-size: $px14;
                        }

                        span {
                            display: block;
                            font-size: 12px;
                            font-family: $plus-font;
                            font-weight: 400;
                            color: #0B192D;
                        }
                    }
                }
            }
        }

        .price {
            width: 25%;
            font-size: $px24;
            color: #0B192D;
            font-family: $secondary-font;
            font-weight: 700;
            text-align: center;

            @media screen and (max-width:768px) {
                width: 100%;
                text-align: right;
            }

            @media screen and (max-width:768px) {
                &:before {
                    content: attr(data-label);
                    float: left;
                    color: #606060;
                    font-size: 16px;
                    font-weight: bold;
                }
            }

            p {
                position: relative;
            }

            span {
                font-weight: 400;
                font-size: 12px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                padding-left: 5px;

                @media screen and (max-width:768px) {
                    position: inherit;
                }
            }

            .offer_price {
                color: #939393;
                text-decoration: line-through;
            }
        }

        .getunit {
            width: 25%;

            .rsv_btn {
                display: flex;
                gap: 21px;
                align-items: center;
                justify-content: flex-end;

                @media screen and (min-width:769px) and (max-width:1199px) {
                    align-items: center;
                    flex-flow: column-reverse;
                    gap: 5px;
                }

                @media screen and (max-width:768px) {
                    flex-flow: column-reverse;
                    gap: 5px;
                    align-items: flex-end;
                }
            }

            @media screen and (max-width:768px) {
                width: 100%;
                justify-content: flex-end;
            }

            @media screen and (max-width:768px) {
                &:before {
                    content: attr(data-label);
                    float: left;
                    color: #606060;
                    font-size: 16px;
                    font-weight: bold;
                }
            }

            .reserve {
                color: #5D5D5D;
                font-size: $px14;
                font-family: $secondary-font;
                font-weight: 700;
                text-decoration: underline;
            }
        }
    }
}

.customer_review.storage {
    background-color: $primary;
    padding: 102px 0 169px;
    border-bottom: solid 4px $yellow;
    box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
    margin-top: 32px;

    @media screen and (max-width:991px) {
        padding: 50px 0;
    }

    &::before {
        background: url(../images/vector1.png) no-repeat;
        position: absolute;
        left: 0;
        top: -168px;
        content: "";
        width: 100%;
        height: 100%;
    }

    &::after {
        background: url(../images/vector2.png) no-repeat bottom right;
        position: absolute;
        right: 0;
        bottom: 0;
        content: "";
        width: 100%;
        height: 100%;
    }

    .review_heading {
        text-align: center;
        position: relative;
        z-index: 222;

        h2 {
            color: $whitecolor;

            span {
                font-weight: 700;
            }
        }

        p {
            color: #BEBEBECC;
        }
    }



}



.storage_sec {
    padding: 82px 0 105px 0;

    @media screen and (max-width:991px) {
        padding: 40px 0;

    }

    .image {
        border-radius: 16px;
        overflow: hidden;
        @include shadow;
    }

    .storage_content {
        padding-left: calc(10px + (40 - 10) * ((100vw - 320px) / (1440 - 320)));

        @media screen and (min-width:1441px) {
            font-size: 40px;
        }

        @media screen and (max-width:767px) {
            padding-left: 0;
            padding-top: 30px;
        }

        h1 {
            font-size: calc(22px + (30 - 22) * ((100vw - 320px) / (1440 - 320)));

            @media screen and (min-width:1441px) {
                font-size: 30px;
            }
        }

        p {
            font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1440 - 320)));
            color: rgba(0, 0, 0, 0.8);
            margin-top: 10px;

            @media screen and (min-width:1441px) {
                font-size: 18px;
            }
        }
    }
}

.green_bar {
    background-color: $primary;
    border-bottom: solid 8px $yellow;
    padding: 30px 0;
    // overflow: hidden;
    position: relative;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .2);

    &::before {
        background: url(../images/vector1.png) no-repeat top left;
        position: absolute;
        left: 0;
        top: -50px;
        content: "";
        width: 100%;
        height: 100%;
        background-size: 450px;
    }

    &::after {
        background: url(../images/vector2.png) no-repeat bottom right;
        position: absolute;
        right: 0;
        bottom: 0;
        content: "";
        width: 100%;
        height: 100%;
        background-size: 450px;
    }

    h2 {
        font-size: calc(30px + (48 - 30) * ((100vw - 320px) / (1440 - 320)));
        font-weight: 600;
        color: $whitecolor;
        line-height: 1.3;
        position: relative;
        z-index: 22;

        @media screen and (min-width:1441px) {
            font-size: 48px;
        }
    }

    h3 {
        font-size: calc(22px + (36 - 22) * ((100vw - 320px) / (1440 - 320)));
        font-weight: 600;
        color: $whitecolor;
        line-height: 1.3;
        position: relative;
        z-index: 22;

        @media screen and (min-width:1441px) {
            font-size: 36px;
        }

        strong {
            font-weight: 700;
        }
    }

    p {
        color: #C6C6C6;
        font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1440 - 320)));
        position: relative;
        z-index: 22;

        @media screen and (min-width:1441px) {
            font-size: 24px;
        }
    }

    .left_content {
        @media screen and (min-width:767px) and (max-width:991px) {
            width: 70%;
        }
    }

    .rent_text {
        font-size: calc(18px + (32 - 18) * ((100vw - 320px) / (1440 - 320)));
        font-weight: 600;
        color: #D9D9D9;
        line-height: 1.3;

        @media screen and (min-width:1441px) {
            font-size: 32px;
        }
    }

    .green_inside {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 2;

        @media screen and (max-width:767px) {
            flex-flow: column;
            text-align: center;
            gap: 20px;
        }

        .btn {
            padding: 18px 42px;
        }
    }
}

.subpageabout {
    padding: 68px 0 84px 0;

    @media screen and (max-width:767px) {
        padding: 40px 0 30px 0;
    }

    .sls_info {
        margin-top: 0;
    }
}

.step {
    max-width: 764px;
    margin: 67px auto 0;

    .step_counter {
        li {
            width: 25%;
        }

        .step_link {
            display: flex;
            background-color: #E0E0E0;
            width: 100%;
            height: 18px;
            margin-top: 6px;

            li a {
                &.active {
                    background-color: #056600;
                    position: relative;
                    width: 32%;
                    display: inline-block;
                    height: 100%;

                    @media screen and (max-width:576px) {
                        width: 50%;
                    }

                    &::after {
                        position: absolute;
                        right: -8px;
                        content: "";
                        width: 0;
                        height: 0;
                        border-top: 9px solid transparent;
                        border-left: 8px solid #056600;
                        border-bottom: 9px solid transparent;
                    }

                    &::before {
                        color: #056600;
                    }
                }
            }
        }

        .steps_info {
            display: flex;
            padding: 0 20px;

            @media screen and (max-width:767px) {
                padding: 0;
            }

            li {
                a {
                    color: #E0E0E0;
                    font-size: 15px;
                    font-weight: 700;
                    font-family: $secondary-font;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: inline-block;
                    width: 100%;
                    padding-right: 10px;

                    &.active {
                        color: $secondary;
                    }

                    @media screen and (max-width:767px) {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}



.checkout-wrap {
    .checknote {
        margin-top: 54px;
        max-width: 574px;

        @media screen and (max-width:767px) {
            margin-top: 30px;
        }

        p {
            font-size: 20px;
            color: $secondary;
            font-family: $secondary-font;

            @media screen and (max-width:767px) {
                font-size: 16px;
            }

            span {
                font-weight: 600;
            }
        }
    }

    .checkout-item {
        padding-top: 48px;
        padding-bottom: 150px;
    }

    .form-sec {
        max-width: 574px;

        @media screen and (max-width:991px) {
            max-width: 100%;
        }
    }

    h5 {
        font-weight: 700;
        font-size: 20px;

        @media(max-width: 767px) {
            font-size: 14px;
        }
    }

    .checkout-head {
        margin: 40px 0 20px 0;

        display: inline-block;
        width: 100%;
    }

    .checkout-wrap-left {
        padding-right: 35px;
        padding-left: 60px;

        @media(max-width: 991px) {
            padding: 45px 45px 30px;
        }

        @media(max-width: 767px) {
            padding: 30px 15px 30px;
        }

    }

    .form-group {
        height: 48px;
        position: relative;

        .calendar-icon {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            cursor: pointer;
            pointer-events: none;
        }

        .form-control {
            border: 1px solid #cccfd4;
            padding: 0 20px;
            height: 100%;
            width: 100%;
            font-size: 14px;
            background-color: #F9FAFC;
            border-radius: 6px;
            outline: none;
            box-shadow: none;
            color: #0B192D;
            font-weight: 600;

            &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                color: rgba(11, 25, 45, 0.5);
                font-weight: 400;
            }

            &::-moz-placeholder {
                /* Firefox 19+ */
                color: rgba(11, 25, 45, 0.5);
                font-weight: 400;
            }

            &:-ms-input-placeholder {
                /* IE 10+ */
                color: rgba(11, 25, 45, 0.5);
                font-weight: 400;
            }

            &:-moz-placeholder {
                /* Firefox 18- */
                color: rgba(11, 25, 45, 0.5);
                font-weight: 400;
            }
        }

        label {
            font-size: 16px;
            line-height: 1;
            position: absolute;
            left: 10px;
            padding: 2px 10px;
            display: inline-block;
            background-color: transparent;
            top: -9px;
            color: #0B192D;
            font-weight: 500;
            z-index: 1;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                height: 10px;
                width: 100%;
                background-color: #fff;
                top: 0;
                z-index: -1;
            }
        }

    }

    .form-sec {
        .row {
            display: flex;
            flex-wrap: wrap;
            row-gap: 25px;
            margin: 0 -5px;

            >div {
                padding: 0 5px;
            }
        }
    }

    .checkout-wrap-right {
        box-shadow: -18px 0px 15px -19px rgba(0, 0, 0, 0.08);
        padding-left: 48px;
        font-size: 14px;

        @media(max-width: 1199px) {
            padding-left: 30px;
        }

        @media(max-width: 991px) {
            padding: 30px 45px 40px;
            box-shadow: none;
        }

        @media(max-width: 767px) {
            padding: 30px 15px 30px;
        }
    }

    .unit-info-wrap {
        padding: 20px 0;
        border-top: 1px solid #E5EDF9;
        border-bottom: 1px solid #E5EDF9;
        flex-direction: column;
        gap: 42px;
        display: flex;

        @media(max-width: 991px) {
            gap: 32px;
        }

        .unit-info-top {
            flex-wrap: wrap;
            justify-content: space-between;
            display: flex;

            row-gap: 15px;

            p {
                margin: 0;

                @media(max-width: 575px) {
                    br {
                        content: '';
                        display: none;
                    }
                }
            }

            h5 {
                margin: 0;
            }

            h6 {
                margin: 0;
                font-weight: 700;
                font-size: 20px;
            }

            .unit-size {
                width: 40%;

                @media(max-width: 1199px) {
                    width: 30%;
                }

                @media(max-width: 575px) {
                    width: 48%;
                }
            }

            .unit-rate {
                width: 20%;

                @media(max-width: 1199px) {
                    width: 25%;
                }

                @media(max-width: 575px) {
                    width: 48%;
                }
            }

            .unit-add {
                text-align: right;
                width: 40%;

                @media(max-width: 1199px) {
                    width: 45%;
                }

                @media(max-width: 575px) {
                    width: 100%;
                    text-align: left;
                }
            }
        }
    }

    .cart-list-wrap {
        padding: 20px 0;
        border-bottom: 1px solid #E5EDF9;
    }

    .cart-list {
        display: flex;
        justify-content: space-between;

        p {
            margin: 0;
        }

        +.cart-list {
            margin-top: 10px;
        }
    }

    .cart-list-total {
        padding: 20px 0;

        p {
            font-size: 16px;
        }
    }


    select {
        &.form-control {
            background-image: url('../images/down-shape.png');
            background-repeat: no-repeat;
            background-position: right 15px center;
        }
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.renter_box {
    background-color: #F1F1F1;
    padding: 32px 47px 26px 47px;
    border-radius: 8px;
    box-shadow: inset 0 -7px 0 0 rgba(0, 0, 0, .1);

    @media screen and (max-width:767px) {
        padding: 32px 20px 26px 20px;
    }

    .disclamer {
        background-color: $whitecolor;
        border: solid 1px #DADADA;
        padding: 20px 24px;
        margin-top: 41px;

        p {
            color: #505050;
            font-size: $px14;
            padding-right: 10px;
            margin-bottom: 10px;
        }

        .inside {
            max-height: 103px;
            overflow-y: auto;
        }
    }
}

.apply {
    margin-top: 42px;
    padding-left: 19px;
}



/* Shared Input Styles */
.radio .radio-input,
.checkbox .checkbox-input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

.radio .radio-control,
.checkbox .checkbox-control {
    position: relative;
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    border: solid 1px #D0D5DD;
    transition: all 0.25s;
}

/* Checkbox Styles */
.checkbox-group {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    margin-top: 10px;
}

.checkbox {
    position: relative;
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    cursor: pointer;
}

.checkbox .checkbox-control {
    border-radius: 4px;
}

.checkbox .checkbox-control::before,
.checkbox .checkbox-control::after {
    content: "";
    position: absolute;
    transition: transform var(--transition-duration) var(--transition-timing), opacity var(--transition-duration) var(--transition-timing);
    will-change: transform, opacity;
}

.checkbox .checkbox-control::before {
    top: 50%;
    left: 50%;
    width: var(--ripple-size);
    height: var(--ripple-size);
    background-color: var(--ripple-color);
    border-radius: 50%;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
}

.checkbox .checkbox-control::after {
    left: 50%;
    top: 45%;
    width: 6px;
    height: 9px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg) scale(0);
    opacity: 0;
}

.checkbox .checkbox-input:checked~.checkbox-control {
    background-color: $secondary;
    border-color: $secondary;
}

.checkbox .checkbox-input:checked~.checkbox-control::before {
    animation: ripple 240ms linear;
}

.checkbox .checkbox-input:checked~.checkbox-control::after {
    transform: translate(-50%, -50%) rotate(45deg) scale(1);
    opacity: 1;
}

.checkbox .checkbox-input:focus-visible~.checkbox-control {
    box-shadow: 0 0 0 var(--focus-ring-size) rgba(100, 108, 255, 0.3);
}

.checkbox .checkbox-label {
    font-size: $px16;
    user-select: none;
    color: #0B192D;
    transition: color var(--transition-duration) var(--transition-timing);
}

.checkbox .checkbox-input:checked~.checkbox-label {
    color: #0B192D;
    animation: label-check 0.3s var(--transition-timing);
}

.autopay_outer {
    margin-top: 34px;
    gap: 20px;
    display: grid;
    border-bottom: solid 9px $primary;
    padding-bottom: 54px;
    margin-bottom: 108px;

    @media screen and (max-width:1199px) {
        padding-bottom: 50px;
        margin-bottom: 0px;
    }

    .autopy {
        display: flex;
        align-items: flex-start;

        @media screen and (max-width:576px) {
            flex-flow: column;
        }

        .instruction {
            background: url(../images/rectangle.svg) no-repeat;
            width: calc(100% - 32px);
            background-size: cover;
            padding: 3px 10px 10px 52px;
            position: relative;
            margin: 7px 0 0 5px;
            border-right: solid 3px #067300;
            border-radius: 8px;

            @media screen and (min-width:767px) and (max-width:991px) {
                height: 80px;
                display: flex;
                align-items: center;

            }

            @media screen and (max-width:576px) {
                background-image: none;
                height: 100%;
                border-right: 0;
                padding-left: 0;
            }

            p {
                font-size: $px14;
                font-style: italic;
            }
        }

        .checkbox-label {
            font-size: 16px;
            color: $secondary;
            font-weight: 700;
            white-space: nowrap;
        }
    }
}

.rent_summary {
    background-color: $secondary;
    border-radius: 23px;
    padding: 20px 49px 26px;
    @include shadow;
    margin-top: 48px;
    position: relative;
    overflow: hidden;

    @media screen and (max-width:1199px) {
        padding: 20px;
    }

    &::before {
        background: url(../images/vector2.png) bottom;
        position: absolute;
        right: -50px;
        bottom: 0;
        width: 100%;
        height: 50px;
        background-size: 100%;
        content: "";
    }

    h2 {
        color: $whitecolor;
        font-weight: 700;
        font-size: $px24;
        padding: 0 20px;

        @media screen and (max-width:576px) {
            font-size: $px18;
        }
    }

    .summary_row {
        display: flex;
        justify-content: space-between;
        color: $whitecolor;
        font-size: 18px;
        margin-bottom: 5px;

        @media screen and (max-width:767px) {
            font-size: 14px;
        }
    }

    .summary_top {
        margin-top: 18px;
        padding: 0 20px;

        @media screen and (max-width:480px) {
            padding: 0;
        }

        .sum_title {
            font-weight: 700;
        }

        .sum_dis {
            font-weight: 400;
        }
    }

    .summary_botom {
        margin-top: 18px;
        padding: 0 20px;

        @media screen and (max-width:480px) {
            padding: 0;
        }
    }

    .total {
        background-color: #A3DBA0;
        border-radius: 8px;
        padding: 17px 25px;
        font-weight: 700;
        color: $black;
        margin-top: 29px;

        @media screen and (max-width:576px) {
            padding: 10px 15px;
        }

        .total_title {
            font-size: $px18;

            @media screen and (max-width:576px) {
                font-size: 16px;
            }
        }

        .price {
            font-size: $px24;

            @media screen and (max-width:576px) {
                font-size: 18px;
            }
        }
    }
}

.address_info_outer {
    margin-top: 46px;

    .adrees_img {
        border-radius: 16px;
        overflow: hidden;
    }

    .adress_info {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width:600px) {
            flex-flow: column;
        }

        .address {
            max-width: 220px;

            h2 {
                font-size: $px20;
                font-weight: 600;

                span {
                    font-weight: 700;
                }
            }

            p {
                font-size: 16px;
                color: $black;
            }
        }
    }

    .emailinfo {
        text-align: right;

        @media screen and (max-width:600px) {
            text-align: left;
        }

        ul {
            li {
                a {
                    color: $black;

                    &.number {
                        font-weight: 700;
                        font-style: italic;
                        font-size: $px18;
                    }

                    &.email {
                        font-size: 16px;
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .billing {
        font-weight: 700;
        color: #fff;
        background-color: $secondary;
        font-size: $px16;
        text-align: center;
        padding: 4px;
        margin-top: 20px;

        @media screen and (max-width:1199px) {
            font-size: 14px;
        }
    }
}

.customercheck_review {
    margin-top: 74px;

    @media screen and (max-width:600px) {
        margin-top: 30px;
    }
}

.optional {
    .checkout-head {
        @media screen and (min-width:767px) {
            margin-top: 76px;
        }
    }
}

.rentalbtn {
    text-align: right;

    .btn-primary {
        font-size: 16px;
        max-width: 216px;

        @media screen and (max-width:576px) {
            font-size: 14px;
        }
    }

    @media screen and (max-width:576px) {
        text-align: center;
    }
}

.nicescroll-rails {
    background-color: #bcbcbc;
    opacity: 1 !important;
    z-index: 22 !important;

    @media screen and (min-width:767px) {
        width: 10px !important;
    }
}

.nicescroll-cursors {
    background-color: $primary !important;
    border-radius: 0px !important;
    border: 0 !important;
    left: 0;
    right: 0;
    float: none !important;

    @media screen and (min-width:767px) {
        width: 5px !important;
        margin: 0 auto;
    }
}