body.scrollhide {
    overflow-y: hidden;
    height: 100vh;
}

.main_header {
    width: 100%;
    background-color: $whitecolor;

    @media screen and (max-width:991px) {
        display: flex;
        align-items: center;
        position: relative;
    }

    &.is-sticky {
        position: fixed;
        backdrop-filter: blur(10px);
        animation: slideDown 0.35s ease-out;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 2222;
    }

    .main_header.is-sticky .logo {
        max-width: 150px;
    }

    @keyframes slideDown {
        from {
            transform: translateY(-100%);
        }

        to {
            transform: translateY(0);
        }
    }

    .top_header {
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;
        padding: 30px 14px 25px 39px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width:991px) {
            padding: 20px 14px 20px 22px;
        }

        .right_btn {
            display: flex;
            gap: 16px;

            @media screen and (max-width:991px) {
                display: none;
            }
        }

        .logo {
            @media screen and (max-width:991px) {
                max-width: 200px;
            }
        }
    }

    .menu {
        @media screen and (min-width:992px) {
            position: relative;
            background: rgb(69, 70, 69);
            background: linear-gradient(180deg, rgba(69, 70, 69, 1) 0%, rgba(28, 28, 28, 1) 100%);
        }
    }

    .main-menu-warp {
        width: 100%;
        background-color: rgba($black, 0.5);
        // height: 123px;
        -webkit-transition: all 0.5s ease;
        -moz-transition: position 10s;
        -ms-transition: position 10s;
        -o-transition: position 10s;
        transition: all 0.5s ease;
        position: absolute;

        @media screen and (max-width:991px) {
            padding: 10px 0;
        }

        .logo {
            transition: all 0.3s;
        }


        @keyframes smoothScroll {
            0% {
                transform: translateY(-40px);
            }

            100% {
                transform: translateY(0px);
            }
        }

        .logo {
            position: relative;
            bottom: -50px;

            @media screen and (max-width:991px) {
                bottom: 0;
                width: 100px;
            }

            img {
                transition: all 0.3s;
                width: 108px;
                height: 123px;
                object-fit: contain;

                @media screen and (max-width:991px) {
                    width: 70px;
                    height: 80px;
                }
            }
        }

        .menu-area {
            .header-right {
                height: 100%;

                @media screen and (min-width:1200px) {
                    justify-content: flex-end;
                    position: relative;
                }

               
                @media screen and (max-width:1199px) {
                    justify-content: flex-end;
                }
            }
        }
    }




    .main-menu {
        gap: 10px;
        height: 100%;
        justify-content: center;

        @media screen and (min-width:1400px) {
            gap: 85px;
        }

        .inside_menu {
            @media screen and (max-width:991px) {
                height: 100vh;
                display: flex;
                flex-flow: column;
                padding-bottom: 125px;
            }
        }

        nav {
            ul {
                li {
                    display: flex;
                    align-items: center;
                    position: relative;

                    @media(max-width: 991px) {
                        flex-wrap: wrap;
                    }

                    >a {
                        position: relative;
                        transition: all 0.3s;

                       

                    }

                    &:hover {
                        >a {
                            @media screen and (min-width:991px) {
                                @include hover;
                            }

                            @media screen and (max-width:991px) {
                                color: $primary
                            }

                            &::after {
                                opacity: 1;
                            }
                        }
                    }

                    &:last-child {
                        border-bottom: 0;
                    }
                }
            }
        }

        @media(max-width: 991px) {
            display: none;
            position: absolute;
            left: 0;
            top: 100%;
            height: calc(100vh);
            padding-bottom: 100px;
            background: #fff;
            width: 100%;
            z-index: 2222;
            padding-top: 170px;

            @media(max-width: 991px) {
                padding-top: 30px;
            }

            nav ul {
                padding-bottom: 15px;

                li {
                    padding: 0 15px;
                    text-align: right;
                    justify-content: flex-end;
                    font-size: $px20;

                    @media screen and (max-width:1199px) {
                        justify-content: flex-start;
                        text-align: left;
                    }

                    a {
                        padding: 6px 15px;
                        display: block;

                    }

                    .sub-menu {
                        padding-bottom: 0;

                        li {
                            font-weight: 400;
                        }
                    }
                }
            }
        }

        @media(max-width: 991px) {


            nav ul {
                li {
                    font-size: $px16;
                    padding: 10px;
                    justify-content: space-between;
                    padding-left: 36px;
                    padding-right: calc(var(--bs-gutter-x)* .5);

                    a {
                        padding: 3px 0px;
                        font-size: $px20;
                        color: #0B192D;
                        text-transform: capitalize;
                    }

                    .sub-menu {
                        li {
                            a {
                                font-size: $px15;
                                color: $black;
                                font-family: $secondary-font;
                                text-transform: capitalize;
                                font-weight: 700 !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .nav_sec {
        display: flex;
        align-items: center;

        @media screen and (max-width:991px) {
            display: inline-block;
            width: 100%;
        }

        >ul {
            margin: 0;
            padding: 0;

            @media screen and (min-width:1200px) {
                height: 100%;
            }

            >li {
                &:nth-child(1) {
                    @media screen and (min-width:991px) {
                        border-left: solid 1px #464444;

                        &::before {
                            width: 1px;
                            height: 100%;
                            background: #000;
                            content: "";
                            top: 0;
                            left: 0;
                            position: absolute;
                        }
                    }
                }

                @media screen and (min-width:991px) {
                    border-right: solid 1px #464444;

                    &::after {
                        width: 1px;
                        height: 100%;
                        background: #000;
                        content: "";
                        top: 0;
                        right: 0;
                        position: absolute;
                    }
                }


                >a {
                    text-transform: uppercase;
                    display: inline-block;
                    font-family: $secondary-font;
                    font-weight: 700;
                    color: $whitecolor;
                    padding: 17px 42px;
                    font-size: 14px;

                    @media screen and (max-width:991px) {
                        color: $primary
                    }
                }

                &.current-menu-item {
                    >a {
                        color: $primary;
                    }

                    .menu-arrow {
                        color: $primary
                    }
                }

                &.menu-item-has-children {
                    &:hover {
                        .menu-arrow {
                            color: $primary
                        }
                    }

                    .menu-arrow {
                        font-size: $px14;
                        display: inline-block;
                        margin-left: 10px;
                        display: none;

                        @media screen and (max-width:991px) {
                            display: block;
                        }

                    }

                    .sub-menu {
                        display: none;
                        width: 100%;
                        position: absolute;

                        @media(max-width: 991px) {
                            position: relative;
                            width: 100%;
                            padding-left: 30px;
                        }

                        @media(min-width: 991px) {
                            display: none !important
                        }

                        li {
                            a {
                                font-weight: normal;
                            }
                        }

                    }

                    @media(min-width: 991px) {
                        &:hover {
                            .sub-menu {
                                display: block !important;
                                left: inherit;
                                top: 100%;
                                @include hover;
                                padding: 0px 0;
                                font-size: $px14;
                                font-weight: 400;
                                min-width: 100%;
                                width: auto;
                                z-index: 22;

                                @media screen and (min-width:991px) and (max-width:1199px) {
                                    min-width: 180px;
                                }


                                >li {
                                    padding: 0;
                                    height: auto;
                                    line-height: 1.35;
                                    display: block;
                                    padding: 5px 15px;
                                    position: relative;
                                    &:hover {
                                        @include hover;

                                        >a {
                                            color: $whitecolor;
                                            background: transparent;
                                        }
                                    }

                                    >a {

                                        display: block;
                                        padding: 6px 0;
                                        color: $whitecolor;
                                        font-size: 15px;
                                        font-weight: 600;
                                        text-transform: capitalize;
                                        font-family: $secondary-font;

                                        @media screen and (min-width:991px) and (max-width:1199px) {
                                            font-size: 14px;
                                        }

                                        &:after {
                                            background-color: transparent;
                                        }
                                       
                                    }

                                    &:last-child {
                                        a {
                                            border-bottom: none
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.arrow {
                        .menu-arrow {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
    }


    #nav-icon1 {
        width: 25px;
        height: 23px;
        transform: rotate(0deg);
        transition: 0.5s ease-in-out;
        cursor: pointer;
        padding: 0;

        span {
            display: block;
            position: absolute;
            height: 3px;
            width: 100%;
            background-color: #0B192D;
            border-radius: 3px;
            opacity: 1;
            transform: rotate(0deg);
            transition: 0.25s ease-in-out;

            &:nth-child(1) {
                top: 0px;
            }

            &:nth-child(2) {
                top: 10px;
            }

            &:nth-child(3) {
                top: 20px;
            }
        }

        &.open {
            span {
                &:nth-child(1) {
                    top: 10px;
                    transform: rotate(130deg);
                }

                &:nth-child(2) {
                    top: 10px;
                    transform: rotate(-130deg);
                }

               
            }
        }
    }

}

.button_block {
    display: flex;
    flex-flow: column;
    gap: 28px;
    max-width: 283px;
    justify-content: center;
    margin: 0 auto;
    margin-top: auto;


    @media screen and (min-width:991px) {
        display: none;
    }

}