*{
  list-style: none;
}
ul{
  margin: 0;
  padding: 0;
}
img{
  max-width: 100%;
}

p{
  margin: 0;
  padding: 0;
}


@mixin gredient{
  background: rgb(245, 231, 78);
  background: linear-gradient(180deg, rgba(245, 231, 78, 1) 0%, rgba(234, 160, 59, 1) 100%);
  border: 0;
  border-radius: 4px;
  color: #0B192D;
  box-shadow: 0px 0px 0px 0.6px #CCCFD4, inset 0 -2px 0 0 rgba(0,0,0, 0.2);
  transition: all 0.3s;
}

@mixin gredient2{
  background: rgb(200,200,200);
  background: linear-gradient(360deg, rgba(200,200,200,1) 0%, rgba(255,255,255,1) 100%);
  border-radius: 4px;
  color: #0B192D;
  box-shadow: 0px 0px 0px 0.6px #CCCFD4, inset 0 -2px 0 0 #6d6d6d;
}
@mixin shadow{
  box-shadow: 0px -6px 0px 0px rgb(0, 0, 0, 0.2) inset, 0 4px 4px 0px rgb(0, 0, 0, 0.25);
}

@mixin hover{
  background: #2e6200;
    background: linear-gradient(180deg, #25934c 0%, #2e6200 100%);
    color: $whitecolor;
}

.btn-primary {
 @include gredient;

  &:hover {
   @include hover;
  }
  &:active, &:focus-visible{
    color: $black !important;
  }
}

.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active{
  color: $black !important;
}

h1,h2,h3,h4,h5,h6{
  color: $primary;
  margin: 0;
  padding: 0;
  letter-spacing: -0.5px;
}
p{
  letter-spacing: -0.5px;
  color: rgba(0,0,0, 0.8);
}


/* Variables */
:root {
  --primary-color: #646cff;
  --hover-color: #535bf2;
  --background-color: #070707;
  --text-color-white: white;
  --text-color-rest: rgba(255, 255, 255, 0.7);
  --text-color-hover: rgba(255, 255, 255, 0.8);
  --text-color-active: rgba(255, 255, 255, 1);
  --ripple-color: rgba(100, 108, 255, 0.3);
  --spacing-xs: 0.5rem;
  --spacing-sm: 0.75rem;
  --spacing-md: 1rem;
  --spacing-lg: 2rem;
  --transition-duration: 0.25s;
  --transition-timing: cubic-bezier(0.4, 0, 0.2, 1);
  --ripple-duration: 0.7s;
  --control-size: 24px;
  --control-border: 2px;
  --focus-ring-size: 4px;
  --ripple-size: 44px;
  --checkmark-width: 6px;
  --checkmark-height: 12px;
  --radio-dot-size: 12px;
}
